.results {
  max-width: 768px;
  margin: 0;
}
.resultCard {
  margin: 0px 0px 15px 0px;
}
@media (max-width: 768px) {
  .resultCard {
    margin-bottom: 1px;
  }
}
hr {
  width: 100%;
  margin-bottom: 16px;
}
.q-card {
  transition: all 0.3s ease;
}
h4 {
  margin: 35px 0 15px;
}
/*# sourceMappingURL=src/pages/ondemand/car-hire/results/index.css.map */