








































































































.vehicleCard
  min-height 160px
.carMetaChip
  background-color $grey-2
  margin-left: 0px;
  margin-right 8px;
