




























































































.overview-price
  padding 8px
  .total
    font-size 1em
  .price
    margin 4px 0
    font-size 1.6em
  .people
    font-size 0.8em
