.layout-padding {
  max-width: 768px;
}
button {
  width: 100%;
}
span,
.hr {
  margin-top: 16px;
}
/*# sourceMappingURL=src/pages/ondemand/car-hire/summary/index.css.map */