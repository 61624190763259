.resultCard {
  min-height: 160px;
  width: 100vw;
  max-width: 600px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "car-description car-name"   "car-img car-total"   "car-img ."   "car-icons car-icons";
  justify-items: start;
  align-items: start;
  padding: 8px;
}
@media (max-width: 768px) {
  .resultCard {
    margin: 0;
    border-radius: 0;
    max-width: 100vw;
  }
}
.car-description {
  grid-area: car-description;
}
.car-img {
  grid-area: car-img;
}
.car-name {
  grid-area: car-name;
  justify-self: end;
}
.car-total {
  grid-area: car-total;
  justify-self: end;
  align-self: end;
}
.total-text {
  grid-area: total-text;
  justify-self: end;
}
.car-icons {
  grid-area: car-icons;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.car-vendor {
  grid-area: car-vendor;
}
.selected {
  outline: 0.01px solid var(--q-color-primary);
}
.selected .text-h4 {
  color: var(--q-color-primary);
}
.selected .carMetaChip {
  background-color: var(--q-color-primary);
  color: #fff;
}
.carMetaChip {
  background-color: #f5f5f5;
  margin-left: 0px;
}
.details {
  background-color: #fcfcfc;
}
/*# sourceMappingURL=src/pages/ondemand/car-hire/results/results-card.css.map */