




































































.resultCard
  min-height 160px
  width 100vw
  max-width 600px
  display grid
  grid-template-columns repeat(2, 1fr)
  grid-template-areas "car-description car-name" \ "car-img car-total" \ "car-img ." \ "car-icons car-icons"
  justify-items start
  align-items start
  padding 8px
  @media (max-width 768px)
    margin 0
    border-radius 0
    max-width 100vw

.car-description
  grid-area car-description

.car-img
  grid-area car-img

.car-name
  grid-area car-name
  justify-self end

.car-total
  grid-area car-total
  justify-self end
  align-self end

.total-text
  grid-area total-text
  justify-self end

.car-icons
  grid-area car-icons
  width 100%
  display flex
  flex-direction row
  justify-content space-between

.car-vendor
  grid-area car-vendor

.selected
  outline 0.01px solid var(--q-color-primary)
  & .text-h4
    color var(--q-color-primary)
  & .carMetaChip
    background-color: var(--q-color-primary);
    color white
.carMetaChip
  background-color $grey-2
  margin-left 0px
.details
  background-color #fcfcfc
